.text-field-customised {
    .MuiFormLabel-root {
        font-size: 1rem !important;
        font-family: "Heebo", sans-serif;
    }
    .MuiOutlinedInput-input {
        border-color: #fff !important; //for border color
    }
}

.hidden-input-field {
    .MuiListItem-button {
        font-size: 0.95rem;
        font-family: "Heebo", sans-serif;
    }
    .MuiFormLabel-root {
        font-size: 1rem !important;
        font-family: "Heebo", sans-serif;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
        font-family: "Heebo", sans-serif;
    }
    .MuiOutlinedInput {
        border-color: #fff !important; //for border color
    }
    .MuiInputBase-root {
        font-family: "Heebo", sans-serif;
        padding-top: 0px;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 12px) scale(1);
    }
}
