.filled {
  padding: 6px;
  width: 80%;
  background-color: #0073e6  !important;
  color: white !important;
  cursor: pointer;
}

.filled:hover{
    background-color: #0367ca  !important;
}

.outlined {
  padding: 6px;
  width: 80%;
  background-color: white  !important;
  color:  #0073e6 !important;
  border: 1px solid #d2d4d8;
  cursor: pointer;
}

.outlined:hover{
    background-color: #f2f2f3  !important;
}
