.green {
    color: #00ae50;
}
.orange {
    color: #ff5c00;
}
.yellow {
    color: #dd9200;
}
.red {
    color: #e01e37;
}
.purple {
    color: #7660f5;
}
.skyBlue {
    color: #0dbfca;
}
.blue {
    color: #0073e6 !important;
}
.darkblue {
    color: #023E7D  !important;
}
.grey {
    color: #979dac;
}
.grey1 {
    color: #33415c;
}
.grey2 {
    color: #5c677d;
}
.brown {
    color: #996500;
}
.amber {
    color: #ff7e00;
}
.black {
    color: black;
}

.black_border_bottom {
    border-bottom: 3px solid black;
}

.amber_border_bottom {
    border-bottom: 3px solid #ff7e00;
}
.green_border_bottom {
    border-bottom: 3px solid #00ae50;
}
.orange_border_bottom {
    border-bottom: 3px solid #ff5c00;
}
.yellow_border_bottom {
    border-bottom: 3px solid #dd9200;
}
.red_border_bottom {
    border-bottom: 3px solid #e01e37;
}
.purple_border_bottom {
    border-bottom: 3px solid #7660f5;
}
.skyBlue_border_bottom {
    border-bottom: 3px solid #0dbfca;
}
.blue_border_bottom {
    border-bottom: 3px solid #0073e6 !important;
}
.grey_border_bottom {
    border-bottom: 3px solid #979dac;
}
.grey2_border_bottom {
    border-bottom: 3px solid #5c677d;
}
.brown_border_bottom {
    border-bottom: 3px solid #996500;
}

.MuiDrawer-paper {
    overflow-x: hidden !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center;
}
.pointer {
    cursor: pointer;
}

.yellow-background {
    background-color: #dd9200 !important;
}
.green-light-background {
    background-color: rgba(0, 174, 80, 0.08) !important;
}

.green-background {
    background-color: #00ae50 !important;
}

.red-background {
    background-color: #e01e37 !important;
}

.red-light-background {
    background-color: rgba(224, 30, 55, 0.1) !important;
}
.yellow-light-background {
    background-color: #fffdf9 !important;
}

.skyBlue-background {
    background-color: #e1f0ff !important;
}

.badge-primary {
    .MuiBadge-colorPrimary {
        color: white !important;
        background-color: #0073e6 !important;
    }
}

.badge-default {
    .MuiBadge-colorPrimary {
        color: white !important;
        background-color: #979dac !important;
    }
}

.attachActive {
    border-left: 3px solid #0073e6 !important;
}

.medium-red-background {
    background-color: #ffebed !important;
}

.font-biggest {
    font-size: 16px !important;
}

.font-bigger {
    font-size: 14px !important;
}

.font-smallest {
    font-size: 10px !important;
}

.font-smallermid {
    font-size: 11px !important;
}

.font-smaller {
    font-size: 12px !important;
}

.font-boldest {
    font-weight: 700;
}

.font-bolder {
    font-weight: 600;
}

.padding-left-15-per {
    padding-left: 15% !important;
}

.margin-top-5-per {
    margin-top: 5% !important;
}

.margin-top-10-per {
    margin-top: 10% !important;
}

.margin-bottom-10-per {
    margin-bottom: 10% !important;
}

.card-active {
    background-color: #0073e6;
    color: white;
    padding: 2% 5%;
    border-radius: 4px;
}

.bottom-buttons {
    bottom: 10px !important;
    position: absolute !important;
}

.padding-1-percent {
    padding: 1%;
}

.padding-2-percent {
    padding: 2%;
}

.margin-2-percent {
    margin: 2%;
}

.gold-background {
    background-color: #ffe4b0 !important;
}

.border-radius-100 {
    border-radius: 100px !important;
}

.squaregreen {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #00ae50;
    border-radius: 3px;
    margin-bottom: 15px;
}

.squareorange {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #ff5c00;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squareyellow {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #dd9200;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squarered {
    height: 15px;
    width: 15px;
    background-color: #e01e37;
    margin-left: 10%;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squarepurple {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #7660f5;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squareskyBlue {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #0dbfca;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squareblue {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #0073e6;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squaregrey {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #979dac;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squaregrey2 {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #5c677d;
    border-radius: 3px;
    margin-bottom: 15px;
}
.squarebrown {
    height: 15px;
    width: 15px;
    margin-left: 10%;
    background-color: #996500;
    border-radius: 3px;
    margin-bottom: 15px;
}

.yellow-border-left {
    border-left: 3px solid #dd9200;
}
.green-border-left {
    border-left: 3px solid #00ae50;
}
.green_border_bottom {
    border-bottom: 3px solid #00ae50;
}
.orange_border_bottom {
    border-bottom: 3px solid #ff5c00;
}
.yellow_border_bottom {
    border-bottom: 3px solid #dd9200;
}
.red_border_bottom {
    border-bottom: 3px solid #e01e37;
}
.purple_border_bottom {
    border-bottom: 3px solid #7660f5;
}
.skyBlue_border_bottom {
    border-bottom: 3px solid #0dbfca;
}
.blue_border_bottom {
    border-bottom: 3px solid #0073e6 !important;
}
.grey_border_bottom {
    border-bottom: 3px solid #979dac;
}
.grey2_border_bottom {
    border-bottom: 3px solid #5c677d;
}
.brown_border_bottom {
    border-bottom: 3px solid #996500;
}
.amber_border_bottom {
    border-bottom: 3px solid #ff7e00;
}

.notif-cards-header {
    font-weight: 600;
    text-align: left;
    padding: 5% 4%;
}

.blue-border-left {
    border-left: 3px solid #0073e6 !important;
}

.yellow-border-left {
    border-left: 3px solid#dd9200 !important;
}

.green-border-left {
    border-left: 3px solid#00ae50 !important;
}

.zeroLineHeight {
    line-height: 0px !important;
}
.dot {
    height: 7px;
    width: 7px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px;
    cursor: pointer;
}

.dotInfo {
    background-color: #0073e6;
}
