.custom-dropdown {
    .MuiSelect-outlined {
        padding: 7.6px !important;
        font-family: "Heebo", sans-serif;
    }
    .MuiFormLabel-root {
        font-size: 1rem;
        font-family: "Heebo", sans-serif;
        // margin-top: -10px;
    }
    .MuiSelect-selectMenu {
        font-family: "Heebo", sans-serif;
        min-height: 10px;
        text-align: left;
    }
    .MuiInputBase-root {
        font-family: "Heebo", sans-serif;
        padding-top: 0px;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 12px) scale(1);
    }
}

.Completed_dot {
    height: 7px;
    width: 7px;
    background-color: #00ae50;
    border-radius: 50%;
    display: inline-block;
}
.Completed {
    color: #00ae50;
    font-weight: 500;
    font-size: 12px;
}
.Failed_dot {
    height: 7px;
    width: 7px;
    background-color: #e01e37;
    border-radius: 50%;
    display: inline-block;
}
.Failed {
    color: #e01e37;
    font-weight: 500;
    font-size: 12px;
}
.NA_dot {
    height: 7px;
    width: 7px;
    background-color: #979dac;
    border-radius: 50%;
    display: inline-block;
}
.NA {
    color: #979dac;
    font-weight: 500;
    font-size: 12px;
}
.Pending_dot {
    height: 7px;
    width: 7px;
    background-color: #dd9200;
    border-radius: 50%;
    display: inline-block;
}
.Pending {
    color: #dd9200;
    font-weight: 500;
    font-size: 12px;
}

.hidden-dropdown {
    .MuiSelect-outlined {
        padding: 25.5px !important;
    }
    .MuiSelect-selectMenu {
        font-size: 0.95rem;
        font-family: "Heebo", sans-serif;
        text-align: left;
    }
    .MuiListItem-button {
        font-size: 0.95rem;
        font-family: "Heebo", sans-serif;
    }
    .MuiFormLabel-root {
        font-size: 1rem;
        font-family: "Heebo", sans-serif;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
        font-family: "Heebo", sans-serif;
    }
    .MuiOutlinedInput {
        border-color: #fff !important; //for border color
    }
    .MuiInputBase-root {
        font-family: "Heebo", sans-serif;
        padding-top: 0px;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 12px) scale(1);
    }
}

.labelless-dropdown {
    .MuiSelect-outlined {
        padding: 11.5px !important;
    }
    .MuiFormLabel-root {
        font-size: 1rem;
        font-family: "Heebo", sans-serif;
    }
    .MuiSelect-selectMenu {
        font-size: 0.95rem;
        font-family: "Heebo", sans-serif;
        text-align: left;
    }
}
